import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'project-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss'],
  animations: [
    trigger('bookmark', [
      state('unbookmarked', style({
        color: '#92949c',
        opacity: '1',
        transform: 'scale(0.9)'
      })),
      state('bookmarked', style({
        color: '#0077bd',
        opacity: '1',
        transform: 'scale(1)'
      })),
      transition('unbookmarked <=> bookmarked', animate('100ms ease-out'))
    ])
  ]
})
export class BookmarkComponent implements OnInit {
  @Input() following: boolean;
  @Input() projectId: string;
  public bookmarkState: string;
  public iconName: string;

  constructor(private api: ApiService) {
  this.bookmarkState = 'unbookmarked';
  this.iconName = 'star-outline';
  }
  ngOnInit() {
    if (this.following) {
      this.bookmarkState = 'bookmarked';
    }
  }
  toggleBookmarkState() {
    if (this.bookmarkState === 'unbookmarked') {
      this.bookmarkState = 'bookmarked';
      this.iconName = 'star-outline';
    } else {
      this.bookmarkState = 'unbookmarked';
      this.iconName = 'star-outline';
    }
  }
  toggleFollow(): void {
    this.api.get('/project/' + this.projectId + '/following?action=toggle').subscribe((data) => {
      if (data.res == false) {
        this.bookmarkState = 'unbookmarked';
      } else if (data.res == true) {
        this.bookmarkState = 'bookmarked';
      }

    });

  }

}

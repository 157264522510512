import {Component, Input, OnInit} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'project-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
  animations: [
    trigger('subscribe', [
      state('unsubscribed', style({
        color: '#92949c',
        opacity: '1',
        transform: 'scale(0.9)'
      })),
      state('subscribed', style({
        color: '#0077bd',
        opacity: '1',
        transform: 'scale(1)'
      })),
      transition('unsubscribed <=> subscribeed', animate('100ms ease-out'))
    ])
  ]
})
export class SubscribeComponent implements OnInit {
  @Input() subscribe: boolean;
  @Input() projectId: string;
  public subscribeState: string;
  public iconName: string;

  constructor(private api: ApiService) {
  this.subscribeState = 'unsubscribed';
  this.iconName = 'mail-outline';
  }
  ngOnInit() {
    if (this.subscribe) {
      this.subscribeState = 'subscribed';
    }
  }
  toggleSubscribeState() {
    this.api.get('/project/' + this.projectId + '/emailalert?action=toggle').subscribe((data) => {
      if (data.res == false) {
        this.subscribeState = 'unsubscribed';
      } else if (data.res == true) {
        this.subscribeState = 'subscribed';
      }

    });
  }


}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'collapsible-card',
  templateUrl: './collapsible-card.component.html',
  styleUrls: ['./collapsible-card.component.scss'],
})
export class CollapsibleCardComponent implements OnInit {
  @Input() title: any;
  public isCollapsed = false;
  constructor() { }

  ngOnInit() {}
 toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
 }
}

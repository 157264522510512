import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';


import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { AuthService} from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = 'https://api.dmsprojects.net/api/v1';


  constructor(
    private http: HttpClient,
    public auth: AuthService,
    private router: Router
  ) {

  }

 /* private get _authHeader(): string {
    return `Bearer ${localStorage.getItem('access_token')}`;
  }*/

  public searchCompany(searchTerm){

    return this.http
      .get(this.baseUrl + '/company/companylist?name=' + searchTerm, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${this.auth.getToken()}`)
      })
      .pipe(
        catchError((error) => this._handleError(error))
      );
  }

  public getProject(id): any {


    return this.http
      .get(this.baseUrl + '/project/' + id, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${this.auth.getToken()}`)
      })
      .pipe(
        catchError((error) => this._handleError(error))
      );


  }
  public getMessageCount(): any {
      return this.http
          .get(this.baseUrl + '/user/new-messages', {
              headers: new HttpHeaders().set('Authorization', `Bearer ${this.auth.getToken()}`)
          })
          .pipe(
              catchError((error) => this._handleError(error))
          );
  }

  public get(url: string, body?: Object) {
      if (this.auth.isAuthenticated() ) {
          if (body) {
              const params = new HttpParams(body);
              return this.http
                  .get(this.baseUrl + url, {
                      params: params, headers: new HttpHeaders().set('Authorization', `Bearer ${this.auth.getToken()}`)
                  })
                  .pipe(
                      catchError((error) => this._handleError(error))
                  );
          } else {
              return this.http
                  .get(this.baseUrl + url, {
                      headers: new HttpHeaders().set('Authorization', `Bearer ${this.auth.getToken()}`)
                  })
                  .pipe(
                      catchError((error) => this._handleError(error))
                  );
          }
      }else {
          this.auth.logout();
      }



  }

  public post(url: string, body: Object) {
    // return this.request(url, 'POST', body);
    let requestBody = {};
    if (body) {
      requestBody = Object.assign({}, body);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.auth.getToken()}`
      })
    };
    return this.http.post(this.baseUrl + url, requestBody, httpOptions)
      .pipe(
        catchError((error) => this._handleError(error))
      );
  }

  public download(url: string, body: Object, options: {}) {
        // return this.request(url, 'POST', body);
        let requestBody = {};
        if (body) {
            requestBody = Object.assign({}, body);
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.auth.getToken()}`
            }),
            ...options
        };
        return this.http.post(this.baseUrl + url, requestBody, httpOptions)
            .pipe(
                catchError((error) => this._handleError(error))
            );
    }

    public put(url: string, body: Object) {
    return this.request(url, 'PUT', body);
  }

  public delete(url: string) {
    return this.request(url, 'DELETE');
  }

  public request(url: string, method: string, body?: Object) {
    /*return new HttpRequest(
      method, url, body, {
                             params, headers, reportProgress, responseType, withCredentials,
                         });*/
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.auth.getToken()}`
      })
    };

    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${this.auth.getToken()}`);
    let requestBody = {};
    if (body) {
      requestBody = Object.assign({}, body);
    }
    const requestOptions = new HttpRequest(method, this.baseUrl + url, requestBody, {headers: headers});

    return this.http.request(requestOptions)
      .pipe(
        catchError((error) => this._handleError(error))
      );
  }

  onRequestError(res: HttpResponse<any>) {
    const statusCode = res.status;
    const body = res.body();

    if (res.status === 401) {
      console.log('JWT Expired');
    }
    const error = {
      statusCode: statusCode,
      // error: body.error
    };
    console.log('-------');
    console.log(error);

    return Observable.throw(error);
  }

  private _handleError(err: HttpErrorResponse | any): Observable<any> {

    const errorMsg = err.message || 'Error: Unable to complete request.';
    if (err.status === 401) {
      this.auth.logout();
    }
    if (err.status === 500) {
      // this.auth.login();
      console.log('Server error, please try again');
    } else if (err.status === 403 ) {
        if (this.router.url === '/' ) {
            this.router.navigate(['/']);
        } else {

        }
    }
    return Observable.throw(errorMsg);
  }

}

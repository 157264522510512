import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CollapsibleCardComponent} from './collapsible-card/collapsible-card.component';
import {BookmarkComponent} from './bookmark/bookmark.component';
import {SubscribeComponent} from './subscribe/subscribe.component';
import {FollowCompanyComponent} from './follow-company/follow-company.component';
import {IonicModule} from '@ionic/angular';

@NgModule({
  declarations: [
    CollapsibleCardComponent,
    BookmarkComponent,
    SubscribeComponent,
    FollowCompanyComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    CollapsibleCardComponent,
    BookmarkComponent,
    SubscribeComponent,
    FollowCompanyComponent
  ]
})
export class SharedComponentsModule {
}

import {Component, Input, OnInit} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'company-bookmark',
  templateUrl: './follow-company.component.html',
  styleUrls: ['./follow-company.component.scss'],
  animations: [
    trigger('bookmark', [
      state('unbookmarked', style({
        color: '#92949c',
        opacity: '1',
        transform: 'scale(0.9)'
      })),
      state('bookmarked', style({
        color: '#0077bd',
        opacity: '1',
        transform: 'scale(1)'
      })),
      transition('unbookmarked <=> bookmarked', animate('100ms ease-out'))
    ])
  ]
})
export class FollowCompanyComponent implements OnInit {
  @Input() following: boolean;
  @Input() companyId: string;
  public bookmarkState: string;
  public iconName: string;

  constructor(private api: ApiService) {
  this.bookmarkState = 'unbookmarked';
  this.iconName = 'star-outline';
  }
  ngOnInit() {
    if (this.following) {
      this.bookmarkState = 'bookmarked';
    }
  }
  toggleBookmarkState() {
    this.api.get('/company/' + this.companyId + '/follow?action=toggle').subscribe((data) => {

      if (data.res == false) {
        this.bookmarkState = 'unbookmarked';
      } else if (data.res == true) {
        this.bookmarkState = 'bookmarked';
      }
    });
  }

}

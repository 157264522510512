import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router} from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';

//import 'rxjs/add/operator/map';

import { JwtHelperService } from '@auth0/angular-jwt';

export interface User {
  token: string;
  FirstName: string;
  LastName: string;
  email: string;
  id: string;
  role: string;
}
interface AuthResponse {
  token: string;
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {
    redirectUrl: string;
    storageKey: string ;

  constructor(
        private http: HttpClient,
        private router: Router

  ) {

    this.storageKey = 'token';
  }

  public login(email: string, password: string): any {
    return this.http.post<AuthResponse>
    ('https://api.dmsprojects.net/api/v1/authenticate', { email: email, password: password, mobile: true })
        .pipe(
          map((response: AuthResponse) => {
          // login successful if there's a jwt token in the response


         // let res = response.json();

          if (response.token ) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('token', response.token );
          }
      }
      ));
}

public logout(): void {
    // remove user token from the storage and navigate to the login route
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
}

isAuthenticated(): boolean {

    const helper = new JwtHelperService();

    const        token =  localStorage.getItem('token');
    if (token) {
        return !helper.isTokenExpired(token);
    }
    return false;

}


public setToken(token: string): void {
  localStorage.setItem(this.storageKey, token);
}

public getToken() {
  return localStorage.getItem(this.storageKey);
}
/*getUser() {
    return this.http.get('https://api.dmsprojects.net/v1/authenticate/user', this.jwt())
    .map(
        (response: HttpResponse<any>) => response.json()
    );
} */


   /* private jwt() {
    // create authorization header with jwt token
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
        let headers = new Headers({ 'Authorization': 'Bearer ' + currentUser.token });
        return new RequestOptions({ headers: headers });
    }
}*/
}
